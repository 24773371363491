import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "banner w-full bg-white bg-opacity-25 rounded-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "banner relative w-full bg-white bg-opacity-25 rounded-md overflow-hidden",
        style: _normalizeStyle((_ctx.image) ? { backgroundImage: 'url(' + _ctx.image + ')' } : '')
      }, null, 4))
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}