<template>
  <!-- kioskMenu -->
  <div
    class="
      kioskMenu
      px-4
      fixed
      top-0
      h-full
      bg-neutral-900
      flex flex-col
      text-white
      ease-linear
      duration-100
      z-20
    "

    style="width:300px"

    :style="showMenu ? 'right:0' : 'right:-300px'"
  >
    <!-- header -->
    <div class="header w-full py-4 flex items-center justify-between">
      <p class="text-xl font-bold">Configuración</p>
      <div class="close-icon w-6 h-6 cursor-pointer" @click="toggleMenu(false)">
        <img src="/assets/icons/close-outline.svg" alt="close" />
      </div>
    </div>
    <!-- body -->
    <div class="body overflow-auto flex-1">
      <div class="section-divider py-4 mb-4 border-b border-gray-600">
        <p>Impresión de tickets</p>
      </div>
      <label for="printer">
        <p class="text-sm mb-2">Impresora:</p>
      </label>
      <select
        name="printer"
        id="printer"
        class="w-full text-black p-2 rounded-md outline-none"
        @change="setPrinter($event)"
      >
        <option disabled>Seleccione Una</option>
        <option
          v-for="(printer, index) in printers"
          :key="index"
          :value="printer.name"
          :selected="printer.selected"
        >
          {{ printer.displayName }}
        </option>
      </select>

      <!-- Print Test -->
      <input
        class="w-full py-2 text-black rounded-md mt-4 bg-neutral-200"
        type="button"
        value="Imprimir Ticket Prueba"
        @click="printTest()"
      />
    </div>
    <!-- quit app -->
    <div
      class="
        quit
        py-6
        border-t
        border-b
        border-gray-600
        flex
        justify-between
        cursor-pointer
      "
      @click="closeApp()"
    >
      <p>Cerrar App</p>
      <div
        class="icon w-5 h-5 bg-white"
        :style="'-webkit-mask-image: url(/assets/icons/power-outline.svg); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center;'"
      ></div>
    </div>

    <!-- app version -->
    <div class="appVersion text-center py-4">
      <p>GAF Kiosk v{{appVersion}}</p>
    </div>

    <!-- TOAST FOR CONFIG CHANGES -->
    <Toast ref="toast" />
  </div>
</template>

<script lang="ts">
//set electron
const { electron } = window as any;

import utilities from "@/utilities";

//custom components
import Toast from "@/components/Toast.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "KioskMenu",
  data() {
    return {
      appVersion: "",
      showMenu: false,
      printers: [] as any[any],
    };
  },
  components: {
    Toast,
  },
  mounted() {
    //get app version
    electron.send("app_version");
    electron.on("app_version", (arg: any[any]) => {
      this.appVersion = arg[0].version
    })

    //get printers
    electron.send("getPrinters");
    electron.on("receivePrinters", (printers: any) => {
      this.printers = printers[0];
      
      //set stored printer
      this.printers.forEach((printer:any)=>{
        if(printer.selected){
          this.$store.commit("setPrinter", printer.name);
        }
      })
    });
  },
  methods: {
    toggleMenu(status: boolean) {
      this.showMenu = status;
    },
    setPrinter(event: Event) {
      const selectedPrinter = (event.target as HTMLSelectElement).value
      electron.send("setPrinter", {name: selectedPrinter});
      this.$store.commit("setPrinter", selectedPrinter);
      const toast = this.$refs.toast as any;
      toast.show("Se cambió la impresora a: " + this.$store.state.printer);
    },
    printTest() {
      const toast = this.$refs.toast as any;
      if (this.$store.state.printer !== "") {
        const ticket = {
          "cine":"CINE PRUEBA",
          "sala":"Prueba",
          "fechaVenta":"00/00/000",
          "horaVenta":"00:00",
          "fechaFuncion":"00/00/0000",
          "horaFuncion":"00:00",
          "precio":0,
          "nroEntrada":"000",
          "pelicula":"PELICULA PRUEBA",
          "codigoSala":"000000",
          "funcion":0,
          "cuit":"00-000000-00"}

        const formattedTicket = utilities.getFormattedTicket(ticket)
        const printData = {
            printer: this.$store.state.printer,
            ticket: formattedTicket
        }

        electron.send('print', printData )
      } else {
        toast.show("Primero debe seleccionar una impresora");
      }
    },
    closeApp() {
      electron.send("closeApp");
    },
  },
});
</script>
