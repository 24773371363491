<template>
  <div
    ref="navbar"
    class="
      h-20
      flex
      gap-4
      py-4
      md:py-3
      items-center
      justify-between
      px-4
      lg:px-10
    ">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
    name: 'NavBar',
}
</script>


