import { defineComponent } from "vue"

export default defineComponent({
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },

    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })

        if(navigator.serviceWorker){
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                // We'll also need to add 'refreshing' to our data originally set to false.
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }

    },

    methods: {
        updateAvailable(event:any) {
            this.registration = event.detail
            this.updateExists = true
          },
      
          // Called when the user accepts the update
          refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !(this.registration as any).waiting) return
            // send message to SW to skip the waiting and activate the new SW
            (this.registration as any).waiting.postMessage({ type: 'SKIP_WAITING' })
          },
    }
})
