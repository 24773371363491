<template>
  <!-- banner -->
  <div
    v-if="image"
    class="
      banner
      relative
      w-full
      bg-white
      bg-opacity-25
      rounded-md
      overflow-hidden"
    :style="(image) ? { backgroundImage: 'url(' + image + ')' } : ''">
  </div>

  <!-- skeleton -->
  <div v-else
    class="
      banner
      w-full
      bg-white
      bg-opacity-25
      rounded-md"
  />
</template>

<script lang="ts">
import './components.css'

import { defineComponent } from 'vue'

export default defineComponent({
  name: "BannerComponent",
  props:{
    image: {
      type: String
    },
    data: {
			type: Object
    },
    showButton: {
      type: Boolean,
      default: true
    },
    showText:{
      type: Boolean,
      default: true
    }
  }
});
</script>

<style>
.banner {
    padding-top: 35%;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
}

/*@media (max-width: 768px) {
    .banner {
        padding-top: 45%;
    }
}*/
</style>


