//create store
import { createStore } from 'vuex'

//vuex persist
//import vuexPersistance from 'vuex-persist';

export default createStore({
    state: {
        webAppVersion: '',
        cinemas:[],
        cinemaInfo: {},
        cart: {},
        devMode: false,
        kioskMode: false,
        printer: ''
    },
    mutations: {
        setPrinter(state, data) {
            state.printer = data
        },
        setCinemaInfo(state, data) {
            state.cinemaInfo = data
        },
        setCart(state, data) {
            state.cart = data
        }
    }
    /*,
    plugins:[
        new vuexPersistance({
            storage: window.localStorage
        }).plugin
    ]*/
})